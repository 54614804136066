
// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components

import Menu from "components/menu/MainMenu";
// Assets


import {useGlobalBusinessContext} from "../../../contexts/BusinessContext"
import { locationColumnsData } from "./variables/columnsData";
import LocationsDataTable from "./variables/LocationsDataTable";
import { useEffect } from "react";
const  LocationsPage = () => {
 const {locations, fetchLocations, status} = useGlobalBusinessContext()

 useEffect(() => {
fetchLocations()
 },[])
 
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex
        mb='20px'
        flexDirection="column"
        gap="40px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
      <div style={{backgroundColor: "white", marginTop: 40, borderRadius: 20, padding: "30px 0", marginBottom: 40, overflowX: "scroll"}}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Locations
        </Text>
        <Menu />
        </Flex>
        <LocationsDataTable columnsData={locationColumnsData} tableData={locations} />
      </div>
      </Flex>

      {/* Delete Product */}
    </Box>
  );
}
export default LocationsPage