export const locationColumnsData = [
	{
		Header: "NAME",
		accessor: "name",
	},
	{
		Header: "ADDRESS",
		accessor: "address",
	},
	{
		Header: "lOCATION TYPE",
		accessor: "locationType",
	},

	{
		Header: "GUEST AMOUNT",
		accessor: "guestAmount",
	},
	{
		Header: "PRICE",
		accessor: "price",
	},
	{
		Header: "PROMOTED",
		accessor: "isPromoted",
	},

	{
		Header: "STATUS",
		accessor: "isDisabled",
	},
];
