import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdOutlineSettings
} from "react-icons/md";
import { FiUsers, FiUserCheck  } from "react-icons/fi";
import { FaRegAddressBook, FaMoneyBillTransfer, FaBuildingColumns } from "react-icons/fa6";
// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import UserDetails from "views/admin/userDetails";
import Locations from "views/admin/Locations";
import LocationDetails from "views/admin/locationDetails";


// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Overview",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: (
      <Icon
        as={FiUsers}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Vendors",
    layout: "/admin",
    icon: <Icon as={FiUserCheck} width='20px' height='20px' color='inherit' />,
    path: "/vendors",
    component: DataTables,
  },
  {
    name: "Bookings",
    layout: "/admin",
    path: "/bookings",
    icon: <Icon as={FaRegAddressBook} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
   {
    name: "Transactions",
    layout: "/admin",
    path: "/transactions",
    icon: <Icon as={FaMoneyBillTransfer} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
   {
    name: "Apartments",
    layout: "/admin",
    path: "/apartments",
    icon: <Icon as={FaBuildingColumns} width='20px' height='20px' color='inherit' />,
    component: Locations,
  },
   {
    name: "User Details",
    layout: "/single-route",
    path: "/user",
    params: "/:id",
    component: UserDetails,
  },
  {
    name: "Location Details",
    layout: "/single-route",
    path: "/locations",
    params: "/:id",
    component: LocationDetails,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={MdOutlineSettings} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Logout",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
 
];

export default routes;
