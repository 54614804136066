import {
    Box,
    Flex,
    Progress,
    Text,
  } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGlobalBusinessContext } from "../../../contexts/BusinessContext";
  

const LocationDetails = () => {
    const {id} = useParams()
    const {singleLocation, status, fetchSingleLocation} = useGlobalBusinessContext()
    useEffect(()=>{
        fetchSingleLocation(id)
    },[id])

    if(status === "pending") return (<Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
    <Flex
      mb='20px'
      flexDirection="column"
      gap="40px"
      columns={{ sm: 1, md: 2 }}
      spacing={{ base: "20px", xl: "20px" }}>
    <div style={{backgroundColor: "white", marginTop: 40, borderRadius: 20, padding: "30px 0", marginBottom: 40,}}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
      <Progress />
      </Flex>
    </div>
    </Flex>
  </Box>)
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
    <Flex
      mb='20px'
      flexDirection="column"
      gap="20px"
      columns={{ sm: 1, md: 2 }}
      spacing={{ base: "20px", xl: "20px" }}>
    <div style={{backgroundColor: "white", marginTop: 40, borderRadius: 20, padding: "30px 0",}}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
      <Text
        fontSize='22px'
        fontWeight='700'
        lineHeight='100%'>
        Location Details
      </Text>
      </Flex>
    </div>
    <div style={{backgroundColor: "white", borderRadius: 20, padding: "30px 0", marginBottom: 40,}}>
      <Flex px='25px' gap="10px" mb='20px' align='center'>
      <Text
        fontSize='22px'
        fontWeight='700'
        lineHeight='100%'>
       {singleLocation?.name}
      </Text>
      <Text
        fontSize='14px'
        fontWeight='700'
        lineHeight='100%'>
       {singleLocation?.address}
      </Text>
      </Flex>
    </div>
    </Flex>
  </Box>
  )
}

export default LocationDetails