import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASEURL;

export const getAdminFromSessionStorage = () => {
	const admin = sessionStorage.getItem("admin");

	if (admin) {
		return JSON.parse(admin);
	} else {
		return {};
	}
};
export const axiosInstance = axios.create({
	baseURL: BASE_URL,
});
export const axiosPrivate = axios.create({
	baseURL: BASE_URL,
	headers: {
		Authorization: `Bearer ${getAdminFromSessionStorage()?.access?.token}`,
		"Content-Type": "application/json",
	},
});

export const nigerianCurrencyFormat = (price: number) => {
	const currencyFormat = new Intl.NumberFormat("en-NG", {
		style: "currency",
		currency: "NGN",
	}).format(price);
	return currencyFormat;
};
