import React from "react";
import { createRoot } from "react-dom/client";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import AuthContext from "contexts/AuthContext";
import ProtectedRoute from "views/auth/ProtectedRoute";
import BusinessContext from "contexts/BusinessContext";
const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<ThemeEditorProvider>
			<ChakraProvider theme={theme}>
				<AuthContext>
					<BusinessContext>
						<HashRouter>
							<Switch>
								<Route path={`/auth`} component={AuthLayout} />
								{/* <ProtectedRoute> */}
								<Route path={`/admin`} component={AdminLayout} />
								{/* </ProtectedRoute> */}
								<Redirect from="/" to="/auth/sign-in" />
							</Switch>
						</HashRouter>
					</BusinessContext>
				</AuthContext>
			</ChakraProvider>
		</ThemeEditorProvider>
	</React.StrictMode>
);
