// Chakra imports
import { Flex } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode

  return (
    <Flex align='center' direction='column'>
    <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="191" height="45" viewBox="0 0 191 45" fill="none">
            <path
              d="M7.83261 34.3418L0.85791 30.6784L14.2608 4.74308C15.7928 1.77982 18.4895 0.0113463 21.4796 5.33544e-05C24.4697 -0.0112396 27.1687 1.77078 28.7007 4.74308L42.1036 30.6784L35.1266 34.3418L21.7238 8.40876C21.6591 8.27487 21.577 8.1503 21.4796 8.03835C21.3838 8.15105 21.3026 8.27551 21.2377 8.40876L7.83261 34.3418Z"
              fill="black"
            />
            <path
              d="M36.5421 45C34.8383 42.5703 32.5812 40.5883 29.9604 39.2204C27.3397 37.8525 24.4317 37.1385 21.4807 37.1385C18.5296 37.1385 15.6217 37.8525 13.0009 39.2204C10.3802 40.5883 8.12309 42.5703 6.41923 45L0 40.4083C2.43061 36.9439 5.64983 34.1179 9.38749 32.1675C13.1252 30.2171 17.2722 29.1992 21.4807 29.1992C25.6891 29.1992 29.8362 30.2171 33.5739 32.1675C37.3115 34.1179 40.5307 36.9439 42.9614 40.4083L36.5421 45Z"
              fill="black"
            />
            <path
              d="M74.9744 35.6065L69.1106 35.6201L68.8284 32.5868C68.8284 32.5868 66.8954 36.0176 60.9399 36.0176C56.1109 36.0176 53.2462 33.4925 52.6862 30.3192C51.9785 26.317 54.7021 22.8456 58.6687 22.0686C62.1471 21.391 66.8887 21.6824 68.423 19.8846C69.0524 19.1483 69.0344 17.9331 68.1833 16.8513C67.2157 15.6204 65.3634 15.189 63.0027 15.2499C60.1694 15.3222 58.597 16.5328 58.0752 18.2245L53.5038 17.0952C53.8701 15.7967 54.5549 14.6122 55.4949 13.6509C58.2879 10.9044 63.8001 10.4889 67.6973 11.4465C69.4152 11.8688 71.057 12.8875 71.7894 13.7412C74.1344 16.4809 73.5678 19.4983 73.6663 23.4531L73.7335 30.8251L74.9744 35.6065ZM68.8553 23.9523C66.1182 26.1453 60.2007 24.8105 58.4873 27.2137C57.2823 28.9076 58.1827 30.92 60.2791 31.6111C63.7687 32.7585 68.8463 30.1137 68.8463 25.9872L68.8553 23.9523Z"
              fill="black"
            />
            <path d="M76.9478 35.6111V2.21118H82.0231V35.6111H76.9478Z" fill="black" />
            <path
              d="M99.7557 28.2481L104.708 29.418C104.708 29.418 102.972 35.855 95.0096 35.855C88.4 35.855 83.8331 31.6969 83.7883 22.8727C83.7547 15.4193 87.9162 10.7825 95.0275 10.7825C102.535 10.7825 104.45 17.0229 104.45 17.0229L99.4735 18.3397C99.4735 18.3397 98.6515 14.7011 94.6177 14.9202C91.1505 15.1099 89.1324 18.0619 88.9936 22.9878C88.9936 28.6975 91.3319 31.2814 94.4429 31.5208C98.5798 31.8415 99.7557 28.2481 99.7557 28.2481Z"
              fill="black"
            />
            <path
              d="M128.232 35.6065L122.359 35.6201L122.077 32.5868C122.077 32.5868 120.144 36.0176 114.188 36.0176C109.36 36.0176 106.495 33.4925 105.935 30.3192C105.225 26.317 107.951 22.8456 111.915 22.0686C115.396 21.391 120.135 21.6824 121.669 19.8846C122.301 19.1483 122.283 17.9331 121.432 16.8513C120.462 15.6204 118.61 15.189 116.251 15.2499C113.418 15.3222 111.843 16.5328 111.324 18.2245L106.752 17.0952C107.12 15.7968 107.806 14.6124 108.746 13.6509C111.537 10.9044 117.051 10.4889 120.948 11.4465C122.666 11.8688 124.308 12.8875 125.038 13.7412C127.383 16.4809 126.816 19.4983 126.915 23.4531L126.984 30.8251L128.232 35.6065ZM122.111 23.9523C119.374 26.1453 113.456 24.8105 111.745 27.2137C110.538 28.9076 111.44 30.92 113.537 31.6111C117.024 32.7585 122.102 30.1137 122.102 25.9872L122.111 23.9523Z"
              fill="black"
            />
            <path
              d="M140.544 15.4577C137.525 15.7242 135.823 18.2289 135.435 20.7992C135.299 21.7162 135.35 24.824 135.35 24.824L135.274 35.5229L130.232 35.5048L130.199 11.2928H135.319L135.33 14.6423C135.33 14.6423 136.898 11.1257 141.601 10.8727C143.222 10.7949 144.831 11.1868 146.238 12.002C145.324 13.4791 144.592 14.6446 143.68 16.1194C143.696 16.1127 142.612 15.277 140.544 15.4577Z"
              fill="black"
            />
            <path
              d="M166.624 15.1123L153.696 31.1256H166.624V35.5276H147.181V31.3312L160.395 15.6137L147.181 15.747V11.2727H166.624V15.1123Z"
              fill="black"
            />
            <path
              d="M191 35.6065L185.134 35.6201L184.849 32.5868C184.849 32.5868 182.917 36.0176 176.961 36.0176C172.132 36.0176 169.267 33.4925 168.707 30.3192C167.997 26.317 170.723 22.8456 174.688 22.0686C178.168 21.391 182.908 21.6824 184.442 19.8846C185.073 19.1483 185.056 17.9331 184.204 16.8513C183.235 15.6204 181.382 15.189 179.024 15.2499C176.19 15.3222 174.616 16.5328 174.096 18.2245L169.525 17.0952C169.893 15.7968 170.578 14.6124 171.518 13.6509C174.309 10.9044 179.823 10.4889 183.721 11.4465C185.439 11.8688 187.08 12.8875 187.81 13.7412C190.156 16.4809 189.589 19.4983 189.687 23.4531L189.757 30.8251L191 35.6065ZM184.879 23.9523C182.142 26.1453 176.224 24.8105 174.513 27.2137C173.306 28.9076 174.208 30.92 176.305 31.6111C179.792 32.7585 184.87 30.1137 184.87 25.9872L184.879 23.9523Z"
              fill="black"
            />
          </svg>
        </div>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
