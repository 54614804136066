import { useEffect, useMemo, useState } from "react";
import {
	Button,
	Flex,
	Input,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
import {
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
} from "react-table";
import { useHistory } from "react-router-dom";
import { useGlobalBusinessContext } from "../../../../contexts/BusinessContext";

const LocationsDataTable = (props) => {
	const { totalPage, fetchLocations } = useGlobalBusinessContext();
	const { columnsData, tableData, group } = props;
	const [pageNum, setPage] = useState(1);
	const [filter, setFilter] = useState({
		price: "",
		city: "",
		name: "",
	});
	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);
	const history = useHistory();
	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 2 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		initialState,
		canPreviousPage,
		canNextPage,
		setPageSize,
		state: { pageSize },
	} = tableInstance;
	initialState.pageSize = 11;

	const textColor = useColorModeValue("secondaryGray.900", "white");
	const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
	const handleFilterClicked = async () => {
		if(filter.city || filter.name || filter.price){
			await fetchLocations({numberOfGuest: 3, city: filter.city, name: filter.name, price: filter.price, limit: 20, pageNum:1})
			return
		}
		console.log("filed");
	}
	useEffect(() => {
		fetchLocations({pageNum, limit: pageSize});
	}, [pageNum, pageSize]);

	return (
		<>
			<Flex
				mb="20px"
				flexDirection={{ xl: "row" }}
				alignItems={"center"}
				gap="40px"
				paddingX={"24px"}
				columns={{ sm: 1, md: 2 }}
				spacing={{ base: "20px", xl: "20px" }}>
				<Text>Filter by</Text>
				<Flex alignItems={"center"} gap={"8px"} flexDirection={"column"}>
					<Text>City</Text>
					<Input type="text" name="city" value={filter.city} onChange={(e) => setFilter(prev => {
						return {
							...prev,
							[e.target.name]: e.target.value
						}
					})}/>
				</Flex>
				<Flex alignItems={"center"} gap={"8px"} flexDirection={"column"}>
					<Text>Location name</Text>
					<Input type="text"  name="name" value={filter.name} onChange={(e) => setFilter(prev => {
						return {
							...prev,
							[e.target.name]: e.target.value
						}
					})}  />
				</Flex>
				<Flex alignItems={"center"} gap={"8px"} flexDirection={"column"}>
					<Text>Price</Text>
					<Input type="text" name="price" value={filter.price} onChange={(e) => setFilter(prev => {
						return {
							...prev,
							[e.target.name]: e.target.value
						}
					})} />
				</Flex>
				<Button onClick={handleFilterClicked}>Submit</Button>
			</Flex>
			<Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									pe="10px"
									key={index}
									borderColor={borderColor}>
									<Flex
										justify="space-between"
										align="center"
										fontSize={{ sm: "10px", lg: "12px" }}
										color="gray.400">
										{column.render("Header")}
									</Flex>
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						return (
							<Tr
								{...row.getRowProps()}
								key={index}
								role="button"
								className="table-rows"
								onClick={() => {
									history.push(`/admin/locations/${data[index]?.id}`);
								}}>
								{row.cells.map((cell, index) => {
									let data = "";
									if (cell.column.Header === "NAME") {
										data = (
											<Text color={textColor} fontSize="sm" fontWeight="700">
												{cell.value}
											</Text>
										);
									} else if (cell.column.Header === "ADDRESS") {
										data = (
											<Text color={textColor} fontSize="sm" fontWeight="700">
												{cell.value}
											</Text>
										);
									} else if (cell.column.Header === "lOCATION TYPE") {
										data = (
											<Text color={textColor} fontSize="sm" fontWeight="700">
												{cell.value}
											</Text>
										);
									} else if (cell.column.Header === "GUEST AMOUNT") {
										data = (
											<Flex align="center">
												<Text
													me="10px"
													color={textColor}
													fontSize="sm"
													fontWeight="700">
													{cell.value}
												</Text>
											</Flex>
										);
									} else if (cell.column.Header === "PRICE") {
										data = (
											<Flex align="center">
												<Text
													me="10px"
													color={textColor}
													fontSize="sm"
													fontWeight="700">
													{cell.value}
												</Text>
											</Flex>
										);
									} else if (cell.column.Header === "PROMOTED") {
										data = (
											<Flex align="center">
												<Text
													me="10px"
													color={textColor}
													fontSize="sm"
													fontWeight="700">
													{cell.value}
												</Text>
											</Flex>
										);
									} else if (cell.column.Header === "STATUS") {
										data = (
											<Flex align="center">
												<Text
													me="10px"
													color={textColor}
													fontSize="sm"
													fontWeight="700">
													{cell.value}
												</Text>
											</Flex>
										);
									}
									return (
										<Td
											{...cell.getCellProps()}
											key={index}
											fontSize={{ sm: "14px" }}
											minW={{ sm: "150px", md: ">200px", lg: "auto" }}
											borderColor="transparent">
											{data}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<ul className="pagination">
				<li
					className="page-item"
					onClick={() => {
						setPage(1);
						fetchLocations(1);
					}}
					disabled={!canPreviousPage}>
					<button className="page-link main">First</button>
				</li>
				<li
					className="page-item"
					onClick={() => setPage((prev) => (prev <= 1 ? 1 : prev - 1))}
					disabled={!canPreviousPage}>
					<button className="page-link">{"< Prev"}</button>
				</li>
				<li
					className="page-item"
					onClick={() =>
						setPage((prev) => (prev >= totalPage ? totalPage : prev + 1))
					}
					disabled={!canNextPage}>
					<button className="page-link">{"Next >"}</button>
				</li>
				<li
					className="page-item"
					onClick={() => {
						setPage(totalPage);
						fetchLocations(totalPage);
					}}
					disabled={!canNextPage}>
					<button className="page-link main">Last</button>
				</li>
				<li>
					<button className="page-link">
						Page{" "}
						<strong>
							{pageNum} of {totalPage}
						</strong>{" "}
					</button>
				</li>
				<select
					className="form-control"
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}
					style={{ width: "120px", height: "38px" }}>
					{[2, 4, 6, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</ul>
		</>
	);
};

export default LocationsDataTable;
