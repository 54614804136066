import React, { useMemo } from "react";
/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import TableData from "../TableData"


export default function DevelopmentTable(props) {
  const { columnsData, tableData } = props;
 const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Vendor
        </Text>
        <Menu />
      </Flex>
     <TableData columnsData={columnsData} tableData={tableData} group="User" />
    </Card>
  );
}
