export const columnsDataDevelopment = [
  {
    Header: "FULLNAME",
    accessor: "fullName",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "PHONE",
    accessor: "phone",
  },
  {
    Header: "STATE",
    accessor: "state",
  },
  {
    Header: "ROLE",
    accessor: "role",
  },
  {
    Header: "GENDER",
    accessor: "gender",
  },
  {
    Header: "VERIFIED",
    accessor: "verified",
  },
];



export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
