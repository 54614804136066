import useAxiosPrivate from "components/hooks/useAxiosPrivate";
import { nigerianCurrencyFormat } from "helpers";
import { User } from "helpers/types";
import { createContext, useContext, useEffect, useState } from "react";

type Business = {
	users: (typeof User)[];
	singleUser: any;
	vendors: any;
	bookings: any;
	locations: any;
	singleLocation: any;
	totalPage: any;
	filteredLocations: any;
	status: "idle" | "pending" | "fulfiled" | "failed";
	fetchUsers: () => void;
	fetchSingleUser: (id: string) => Promise<void>;
	fetchLocations: (params: any) => Promise<void>;
	fetchSingleLocation: (id: string) => Promise<void>;
	disableLocation: (id: string) => Promise<void>;
	enableLocation: (id: string) => Promise<void>;
};

export const AppContent = createContext<Business | null>(null);
const BusinessContext: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [status, setStatus] = useState<
		"idle" | "pending" | "fulfiled" | "failed"
	>("idle");
	const [users, setUsers] = useState([]);
	const [singleUser, setSingleUser] = useState({});
	const [vendors, setVendors] = useState([]);
	const [bookings, setBookings] = useState([]);
	const [locations, setLocations] = useState([]);
	const [totalPage, setTotalPage] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);
	const [singleLocation, setSingleLocation] = useState({});
	const axiosPrivate = useAxiosPrivate();

	const fetchUsers = async () => {
		try {
			setStatus("pending");
			const { data } = await axiosPrivate.get("/admin/fetch/users");
			const tranformData = data?.map((user: any) => {
				let verified = user?.verified ? "Verified": "Not verified"
				return {...user, verified }
			})
			setUsers(tranformData);
			setStatus("fulfiled");
		} catch (error) {
			console.log(error);
			setStatus("failed");
		}
	};
	const fetchSingleUser = async (id: string) => {
		try {
			setStatus("pending");
			const { data } = await axiosPrivate.get(`/admin/fetch/users/${id}`);
			setStatus("fulfiled");
			setSingleUser(data)
		} catch (error) {
			console.log(error);
			setStatus("failed");
		}
	};
	const fetchLocations = async (params: any) => {
		
		try {
			let page = params?.pageNum ? params?.pageNum : 1
			let limit = params?.limit ? params?.limit : 20
			let url = `/locations?page=${page}&limit=${limit}`
			if(params?.city){
				url =  url + `&city=${params.city}`
			}
			if(params?.price){
				url =  url + `&price=${params.price}`
			}
			if(params?.numberOfGuest){
				url =  url + `&numberOfGuest=${params.numberOfGuest}`
			}
			setStatus("pending");
			const { data: {data, totalPage} } = await axiosPrivate.get(url);
			const tranformData = data?.map((location: any) => {
				let isPromoted = location?.isPromoted ? "Promoted": "Not promoted"
				let isDisabled = location?.isDisabled ? "Disable" : "Enabled"
				let price = nigerianCurrencyFormat(location?.price)
				return {...location, isPromoted,  isDisabled, price}
			})
			setTotalPage(totalPage)
			setLocations(tranformData);
			setStatus("fulfiled");
		} catch (error) {
			console.log(error);
			setStatus("failed");
		}
	};
	const disableLocation = async (id: string) => {
		try {
			setStatus("pending");
			await axiosPrivate.patch("/admin/disable/locations", {id});
			setStatus("fulfiled");
		} catch (error) {
			console.log(error);
			setStatus("failed");
		}
	}
	const enableLocation = async (id: string) => {
		try {
			setStatus("pending");
			await axiosPrivate.patch("/admin/enable/locations", {id});
			setStatus("fulfiled");
		} catch (error) {
			console.log(error);
			setStatus("failed");
		}
	}
	const fetchSingleLocation = async (id: string) => {
		try {
			setStatus("pending");
			const { data } = await axiosPrivate.get(`/locations/${id}`);
			
			setSingleLocation(data);
			setStatus("fulfiled");
		} catch (error) {
			console.log(error);
			setStatus("failed");
		}
	};
	const fetchVendors = async () => {};
	const fetchBookings = async () => {};

  useEffect(()=>{
	fetchUsers()
    fetchLocations({pageNum: 1, limit: 10})
  },[])

	return (
		<AppContent.Provider
			value={{
				status,
				users,
				singleUser,
				vendors,
				bookings,
				locations,
				totalPage,
				singleLocation,
				filteredLocations,
				fetchUsers,
				fetchSingleUser,
				fetchLocations,
				fetchSingleLocation,
				disableLocation,
				enableLocation
			}}>
			{children}
		</AppContent.Provider>
	);
};
export const useGlobalBusinessContext = () => {
	return useContext(AppContent);
};

export default BusinessContext;
