import { useGlobalAuthContext } from 'contexts/AuthContext';
import { axiosInstance } from 'helpers';

const useRefreshToken = () => {
const {token, setAdmin} = useGlobalAuthContext()
    const refresh = async () => {
        const response = await axiosInstance.get('/refresh', { refreshToken: token?.refresh?.token
        });
        sessionStorage.setItem("admin", JSON.stringify(response.data))
        setAdmin((prev: any) => {
            return { ...prev, ...response?.data}
        });
        return response.data?.access?.token;
    }
    return refresh;
};

export default useRefreshToken;
