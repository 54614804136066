import { axiosPrivate} from "../../helpers";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { useGlobalAuthContext } from "contexts/AuthContext";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const {token} = useGlobalAuthContext()    
    useEffect(() => {   
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 400 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [token, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;