import { getAdminFromSessionStorage } from 'helpers';
import { axiosInstance } from 'helpers';
import {Dispatch, SetStateAction, createContext, useContext, useState} from 'react'
export type User = {
    id:            string;
    fullName:      string;
    email:         string;
    password:      string;
    phone:         string;
    role:          string;
    gender:        null | string;
    source:        string;
    dateOfBirth:   null | string;
    stateOfOrigin: null | string;
    country:       null | string;
    address:       null | string;
    avatar:        null | string;
    isVerified:    boolean;
    createdAt:     Date;
    updatedAt:     Date;
    deactivated:   boolean;
}
type Admin = {
    adminLogin: any;
    adminLogout: () => {};
    status: "idle" | "pending" | "fulfiled" | "failed"
    admin: any;
    token: any;
    setAdmin: Dispatch<SetStateAction<{ userName: string; email: string;}>>;
}


export const AppContent = createContext<Admin | null>(null)
const AuthContext: React.FC<{children: React.ReactNode}> = ({children}) => {
    const [admin, setAdmin] = useState({})
    const [token, setToken] = useState(getAdminFromSessionStorage())
    const [status, setStatus] = useState<"idle" | "pending" | "fulfiled" | "failed">("idle")

    const adminLogin = async (body: any) => {
        try {
            setStatus("pending")
            const {data} = await axiosInstance.post("/login", body)
            sessionStorage.setItem("admin", JSON.stringify(data?.tokens))
            setToken(data?.tokens)
            setAdmin(data?.user)
            setStatus("fulfiled")
            return true
        } catch (error) {
            setStatus("failed")
        }
    }

 const adminLogout = async () => {
        try {
            await axiosInstance.patch("/logout", {refreshToken: token?.refresh?.token})
            sessionStorage.removeItem("admin")
        } catch (error) {
          console.log(error);
            
        }
    }
  
  return (
    <AppContent.Provider value={{admin, status, token, setAdmin, adminLogin, adminLogout}}>{children}</AppContent.Provider>
  )
}
export const useGlobalAuthContext = () => {
    return useContext(AppContent)
}

export default AuthContext